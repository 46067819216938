<template>

  <a class="btn btn-sm"
          @click.prevent="toggleDnD()"
          v-bind:class="{'btn-outline-danger': customer.dnd, 'btn-outline-success': !customer.dnd}">
    <font-awesome-icon v-if="customer.dnd" icon="bell-slash"></font-awesome-icon>
    <font-awesome-icon v-else icon="bell"></font-awesome-icon>
  </a>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DontDisturb',
  props: ['customer'],
  methods: {
    async toggleDnD () {
      let dnd = !this.customer.dnd
      await axios.put(`/api/super-admin/dnd?id=${this.customer.mobile}&dnd=${dnd}`)
      await this.$store.dispatch('customerUpdated', {id: this.customer.id})
      await this.$store.dispatch('toggleDnD', {id: this.customer.mobile, dnd: dnd})

    }
  }
}
</script>

<style scoped>

</style>