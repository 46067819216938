<template id="order-status">
    <span title="Order Status" v-b-popover.hover.top="'Delivery Status Of Order'">
        <b-badge variant="success" v-if="order.orderStatus === 'DELIVERED'">
            <font-awesome-icon :icon="['fas','check']"></font-awesome-icon> Delivered <span v-if="showDate">on {{order.deliveredDateStr}}</span>
        </b-badge>
        <b-badge variant="danger" v-if="order.orderStatus === 'CANCELLED'">
            <font-awesome-icon :icon="['fas','times']"></font-awesome-icon> Cancelled
        </b-badge>
        <b-badge variant="primary" v-if="order.orderStatus === 'OPEN'">
            <font-awesome-icon :icon="['fas','exclamation']"></font-awesome-icon> Pending
        </b-badge>
        <b-badge variant="warning" v-if="order.orderStatus === 'DRAFT'">
            <font-awesome-icon :icon="['fas','exclamation']"></font-awesome-icon> Draft
        </b-badge>
        <b-badge variant="success" v-if="order.orderStatus === 'SCHEDULED'">
            <font-awesome-icon :icon="['fas','truck']"></font-awesome-icon> Scheduled
        </b-badge>
        <b-badge variant="primary" v-if="order.orderStatus === 'WAITING_FOR_PICKUP'">
            <font-awesome-icon :icon="['fas','truck']"></font-awesome-icon> Waiting for Pickup
        </b-badge>
        <b-badge variant="info" v-if="order.orderStatus === 'SHIPPED'">
            <font-awesome-icon :icon="['fas','truck']"></font-awesome-icon> Shipped
        </b-badge>
        <b-badge variant="warning" v-if="order.orderStatus === 'CHECKOUT' && order.paymentStatus === 'PENDING'">
            <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon> Checkout
        </b-badge>
        <b-badge variant="warning" v-if="order.orderStatus === 'CHECKOUT' && order.paymentStatus === 'PAID'">
            <font-awesome-icon :icon="['fas','exclamation']"></font-awesome-icon> Delivery Pending
        </b-badge>
    </span>
</template>
<script>
export default {
  name: 'OrderStatus',
  data: () => ({}),
  props: {
    order: Object,
    showDate: {
      type: Boolean,
      default: false
    }
  },
  created: function () {
  },
  computed: {},
  methods: {}
}
</script>
<style>
</style>