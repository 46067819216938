<template id="shipment-mode">
    <span title="Order Status" v-b-popover.hover.top="'Delivery Status Of Order'">
        <b-badge variant="info" v-if="order.modeOfShipment === 'SHIPROCKET'">
            <font-awesome-icon :icon="['fas','truck']"></font-awesome-icon> ShipRocket
        </b-badge>
        <b-badge variant="warning" v-else-if="order.modeOfShipment === 'WEFAST'">
            <font-awesome-icon :icon="['fas','check']"></font-awesome-icon> WeFast
        </b-badge>
        <b-badge variant="success" v-else>
            <font-awesome-icon :icon="['fas','user']"></font-awesome-icon> Self
        </b-badge>
    </span>
</template>
<script>
    export default {
        name: 'ShipmentMode',
        data: () => ({}),
        props: {
            order: Object
        },
        created: function () {
        },
        computed: {},
        methods: {}
    }
</script>
<style>
</style>