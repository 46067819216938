<template id="payment-status">
    <span title="Payment Status" v-b-popover.hover.top="'Payment Status Of Order'"
          v-if="order.orderStatus !== 'CANCELLED'">
        <b-badge variant="warning" v-if="canPay(order)">
            <font-awesome-icon :icon="['fas','exclamation']"></font-awesome-icon> Pending
        </b-badge>
        <b-badge variant="success" v-if="order.paymentStatus === 'PAID'">
            <font-awesome-icon :icon="['fas','check']"></font-awesome-icon> Paid
        </b-badge>
    </span>
</template>
<script>
export default {
  name: 'PaymentStatus',
  data: () => ({}),
  props: {
    order: Object
  },
  created: function () {
  },
  computed: {},
  methods: {
    canPay: function () {
      return this.order.paymentStatus === 'PENDING' || this.order.paymentStatus === 'LATER' && ['OPEN', 'DELIVERED'].indexOf(this.order.orderStatus) > -1
    }
  }
}
</script>
<style>
</style>