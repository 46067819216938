<template id="make-payment">
  <b-button variant="primary" :disabled="!canPay()" @click.prevent="payOrder" size="sm">
    <font-awesome-icon :icon="['far','money-check']"></font-awesome-icon> Make Payment
    <b-modal :id="modelId" title="Make Payment" @ok="savePayment">
      <div class="row">
        <div class="col-12">
          <b-form>
            <b-form-group label="Customer">
              <b-form-input v-model="payment.customer.name" disabled></b-form-input>
            </b-form-group>

            <table class="table table-sm mt-3 mb-3 table-bordered" v-if="orders.length > 0">
              <thead class="thead-light">
              <tr>
                <th colspan="4" class="text-center">Pending Orders</th>
              </tr>
              <tr>
                <th class="text-center">Order #</th>
                <th class="text-center">Date</th>
                <th class="text-center">Value</th>
                <th class="text-center">Select</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="o of orders" :key="o.id">
                <td class="text-center">
                  {{ o.orderNum }}
                </td>
                <td class="text-center">{{ o.orderDateStr }}</td>
                <td class="text-center">
                  <rupee>{{ o.netValue }}</rupee>
                </td>
                <td class="text-center">
                  <b-form-checkbox switch v-model="o.selected" @change="updateReturn"></b-form-checkbox>
                </td>
              </tr>
              </tbody>
            </table>

            <table class="table table-sm mt-3 mb-3 table-bordered" v-if="returns.length > 0">
              <thead class="thead-light">
              <tr>
                <th colspan="4" class="text-center">Pending Returns</th>
              </tr>
              <tr>
                <th class="text-center">Date</th>
                <th class="text-center">Value</th>
                <th>Use?</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="o of returns" :key="o.id">
                <td class="text-center">
                  {{ o.dateOfReturn }}
                </td>
                <td class="text-center">
                  <rupee>{{ o.totalAmt }}</rupee>
                </td>
                <td>
                  <b-form-checkbox v-model="o.used" @change="updateReturn" switch></b-form-checkbox>
                </td>
              </tr>
              </tbody>
            </table>

            <b-form-group label="Total Amount">
              <b-form-input type="number" :value="totalPayable" disabled></b-form-input>
            </b-form-group>

            <b-form-group label="Payment Mode">
              <b-form-select v-model="payment.modeOfPayment"
                             :options="paymentModes"
                             text-field="name"
                             @input="updateDeduction"
                             value-field="id"></b-form-select>
            </b-form-group>

            <b-form-group label="Deductions (for covers/bottles)">
              <b-form-input type="number" v-model="payment.deductions" @change="updateDeduction"
                            @input="updateDeduction"></b-form-input>
            </b-form-group>

            <b-form-group label="Adjustments (for returns/excess)">
              <b-form-input type="number" v-model="payment.adjustments" @change="updateDeduction"
                            @input="updateDeduction"></b-form-input>
            </b-form-group>
            <div class="mb-3">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="payment.useWallet"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  @change="updateDeduction"
              >
                Use Wallet Amount
              </b-form-checkbox>

            </div>

            <b-form-group label="From Wallet">
              <b-form-input type="number" v-model="payment.walletAmt" :max="payment.walletAmt" min="0"
                            @change="updateDeduction"
                            :disabled="!payment.useWallet"></b-form-input>
            </b-form-group>

            <div class="mb-3">
              <b-form-checkbox
                  id="checkbox-2"
                  v-model="payment.saveExcess"
                  name="checkbox-2"
                  :value="true"
                  :unchecked-value="false"
                  @change="updateDeduction"
              >
                Save Excess Amount in Wallet
              </b-form-checkbox>

            </div>

            <b-form-group label="Excess Amount">
              <b-form-input type="number" v-model="payment.excessAmount" min="0"
                            @change="updateDeduction"
                            :disabled="!payment.saveExcess"></b-form-input>
            </b-form-group>


            <b-form-group label="Amount">
              <b-form-input type="number" v-model="payment.amount"></b-form-input>
            </b-form-group>


            <b-form-group label="Verified" v-if="payment.modeOfPayment !== 'CASH'">
              <b-form-checkbox switch v-model="payment.verified"></b-form-checkbox>
            </b-form-group>

            <b-form-group label="Notify Customer?">
              <b-form-checkbox switch v-model="payment.notifyCustomer"></b-form-checkbox>
            </b-form-group>


            <file-upload v-model="payment.screenshot" :resize="false"></file-upload>

          </b-form>
        </div>
      </div>
    </b-modal>
  </b-button>

</template>
<script>
import axios from 'axios'
import FileUpload from '@/components/file-upload'
import Rupee from '@/components/rupee'

export default {
  name: 'MakePayment',
  components: {FileUpload, Rupee},
  data: () => ({
    payment: {
      customer: {id: null, name: ''},
      verified: true,
      modeOfPayment: 'CASH',
      screenshot: '',
      notifyCustomer: true,
      orderIds: [],
      walletAmt: 0,
      excessAmount: 0,
      useWallet: true,
      saveExcess: true
    },
    paymentModes: [
      {id: "CASH", name: "Cash"},
      {id: "PAYTM", name: "PayTM"},
      {id: "GPAY", name: "GPAY"},
      {id: "PHONEPE", name: "PhonePe"},
      {id: "UPI", name: "Other UPI"},
      {id: "RAZORPAY", name: "RazorPay"},
      {id: "WALLET", name: "Wallet"},
    ],
    orders: [],
    returns: []
  }),
  props: {
    order: Object
  },
  created: function () {
  },
  computed: {
    modelId() {
      return 'pay-order-' + this.order.id
    },
    totalPayable() {
      if (this.orders.length === 0) return this.order.netValue
      else return this.orders.filter(o => o.selected).map(o => o.netValue).reduce((a, b) => a + b, 0)
    }
  },
  methods: {
    async loadOtherOrders() {
      let r = await axios.get(`/api/admin/orders?customerId=${this.payment.customer.id}&paid=UNPAID&status=OPEN,DELIVERED,SCHEDULED,WAITING_FOR_PICKUP,SHIPPED`)
      let r2 = await axios.get(`/api/admin/returns?customerId=${this.payment.customer.id}&used=false`)
      let r3 = await axios.get(`/api/admin/payments/wallets?id=${this.payment.customer.id}`)
      this.orders = r.data.orders.map(o => {
        o.selected = true
        return o
      })
      this.returns = r2.data.map(m => {
        m.used = true
        return m
      })
      this.payment.walletAmt = r3.data.available
      this.payment.orderIds = this.orders.filter(o => o.selected).map(o => o.id)
      await this.updateReturn()
      console.log("pending orders", this.payment.orderIds, 'returns', this.returns)
    },
    canPay: function () {
      return this.order.paymentStatus === 'PENDING' && ['OPEN', 'DELIVERED', 'SCHEDULED', 'SHIPPED', 'WAITING_FOR_PICKUP'].indexOf(this.order.orderStatus) > -1
    },
    async payOrder() {
      this.payment = {
        customer: {
          id: this.order.customer.id,
          name: this.order.customer.name
        },
        amount: this.order.netValue,
        modeOfPayment: 'CASH',
        deductions: 0,
        adjustments: 0,
        order: {id: this.order.id},
        orderIds: [this.order.id],
        excessAmount: 0,
        walletAmt: 0,
        returnIds: []
      }
      await this.loadOtherOrders()
      await this.updateDeduction()
      this.$bvModal.show(`pay-order-${this.order.id}`)
    },
    async updateReturn() {
      setTimeout(async () => {
        this.payment.orderIds = this.orders.filter(o => o.selected).map(o => o.id)
        this.payment.deductions = this.returns.filter(r => r.used).map(r => r.totalAmt).reduce((a, b) => a + b, 0)
        this.payment.returnIds = this.returns.filter(r => r.used).map(r => r.id)
        await this.updateDeduction()
      }, 50)
    },
    async updateDeduction() {
      let deductable = this.totalPayable;

      this.payment.amount = this.totalPayable - this.payment.deductions - this.payment.adjustments -
          (this.payment.useWallet ? deductable : 0) +
          (this.payment.saveExcess ? +this.payment.excessAmount : 0)
    },
    async savePayment() {
      let url = '/api/admin/payments'
      await axios.post(url, this.payment)
      this.$bvModal.hide(this.modalId)
      await this.$emit('payment-done')
    },
  }
}
</script>
<style>
</style>